import { TextLink } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const Content = styled('div', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '24px',
  paddingTop: '24px',
});

const BtGuideButton = styled(TextLink, {
  color: '#0F7FFA!important',
  width: 'fit-content',
  variants: {
    isFetching: {
      true: {
        pointerEvents: 'none',
      },
    },
  },
});

const ImageAndTextWrapper = styled('div', {
  minHeight: '130px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  '@media (min-width: 900px) and (max-width: 1000px)': {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  '& > *:nth-child(2)': {
    width: '70%',
    fontFamily: 'Barlow',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '24px',
    textAlign: 'left',
    opacity: '56%',
  },
});

export const Styled = {
  Content,
  BtGuideButton,
  ImageAndTextWrapper,
};
