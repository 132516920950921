import { Error403 } from '@hexa-ui/components';
import { MAIN_PAGE_REPORTS_SCOPE, UserType } from 'Assets/Constants';
import CustomCanComponent from 'Components/CustomCanComponent/CustomCanComponent';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import MFERoutes from 'Config/MFERoutes';
import { useStore } from 'effector-react';
import { EmbedReportsPage } from 'Pages/EmbedReportsPage/EmbedReportsPage';
import { MainPage } from 'Pages/MainPage/MainPage';
import { ReportsListPage } from 'Pages/ReportsListPage/ReportsListPage';
import { WorkspacesPage } from 'Pages/WorkspacesPage/WorkspacesPage';
import React, { Suspense, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, useLocation } from 'react-router-dom';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';

function RoutesLoading() {
  return (
    <PageContainer
      maxWidth="xl"
      sidebar={false}
      css={{
        position: 'absolute',
        height: 'calc(100vh - 112px)',

        top: '112px',
        width: 'calc(100vw - 32px)',
        padding: '0 16px',
        left: 0,
        right: 0,
        margin: 'auto',
        boxSizing: 'content-box',

        '@desktopSmall': {
          height: 'calc(100vh - 56px)',
          width: 'calc(100vw - 64px)',
          top: '56px',
        },
      }}
    >
      <Loading type="container" />
    </PageContainer>
  );
}

function Routes(): React.ReactElement {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { loggedUserType } = useStore(LoggedUserStore);

  if (loggedUserType === UserType.LOADING) {
    return <RoutesLoading />;
  }

  return (
    <Suspense fallback={<RoutesLoading />}>
      <CustomCanComponent
        perform={[MAIN_PAGE_REPORTS_SCOPE]}
        fallback={
          <Error403
            header={formatMessage({ id: 'UNAUTHORIZED.ERROR_TITLE' })}
            description={formatMessage({ id: 'UNAUTHORIZED.ERROR_DESCRIPTION' })}
            hasButton={false}
          />
        }
      >
        <Switch>
          <Route exact path={MFERoutes.MAIN_PAGE} component={MainPage} />
          <Route exact path={MFERoutes.REPORTS_LIST} component={ReportsListPage} />
          <Route exact path={MFERoutes.WORKSPACES_LIST} component={WorkspacesPage} />
          <Route exact path={MFERoutes.EMBED_REPORTS} component={EmbedReportsPage} />
        </Switch>
      </CustomCanComponent>
    </Suspense>
  );
}
export default Routes;
