import { Heading, Paragraph, Tooltip } from '@hexa-ui/components';
import { Download } from '@hexa-ui/icons';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import BTGuideSection from 'Components/BTGuideSection/BTGuideSection';
import CSVDownloadModal from 'Components/CSVDownloadModal/CSVDownloadModal';
import { HeaderContainer } from 'Components/HeaderContainer/HeaderContainer';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { CSVDownload } from 'Domain/NewMenuItem';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import usePageViewed from 'Hooks/segment/usePageViewedEvent/usePageViewedEvent';
import { useCSVDownload } from 'Hooks/useCSVDownload/useCsvDownload';
import { useHelpCenter } from 'Hooks/useHelpCenter/useHelpCenter';
import { useReports } from 'Hooks/useReports/useReports';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useGetDatasets } from 'Services/GetDatasets/useGetDatasets';
import { BigNumbersSection } from '../BigNumbersSection/BigNumbersSection';
import DatasetTable from '../DatasetTable/DatasetTable';
import { Styled } from '../MainPage.style';
import { ReportsSection } from '../ReportsSection/ReportsSection';
import { WorkspaceSection } from '../WorkspaceSection/WorkspaceSection';
import {
  CardDataset,
  Container,
  ContainerTitle,
  DownloadIconWrapper,
} from './MainPageContent.style';
import { MainPageContentProps } from './MainPageContent.types';

interface ContentReportViewProps {
  csvDownload: CSVDownload[];
  reportId: string;
  index: number;
}

export function DownloadIcon({
  csvDownload,
  reportId,
  index,
}: Readonly<ContentReportViewProps>): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { triggerElementClicked } = useElementClickedEvent();

  const { downloadCSV } = useCSVDownload({
    originDownload: 'MAIN_PAGE',
    csvDownload,
    setError,
    setIsLoading,
  });

  const { indexCSVDownload } = useHelpCenter(csvDownload);

  const openModal = () => {
    triggerElementClicked({
      is_core_event: false,
      page_label: 'Main Page',
      page_name: 'MAIN_PAGE',
      element_location_type: 'SECTION',
      element_location_name: 'DATASETS',
      element_location_label: 'Datasets',
      element_label: 'Icon: Download',
      element_name: 'DOWNLOAD_ICON',
      element_position: index,
      element_type: 'BUTTON',
      occurence_id: '2.17',
    });
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  /* istanbul ignore next */
  const handleDownload = (period: { year: number; month: number }, sellerId?: string) => {
    triggerElementClicked({
      is_core_event: true,
      page_label: 'Main Page',
      page_name: 'MAIN_PAGE',
      element_location_type: 'MODAL',
      element_location_name: 'DOWNLOAD_CSV',
      element_location_label: 'Download CSV file',
      element_label: 'Download',
      element_name: 'DOWNLOAD_BUTTON',
      element_type: 'BUTTON',
      occurence_id: '2.18',
    });
    downloadCSV(period, sellerId, reportId);
  };

  return (
    <>
      <DownloadIconWrapper>
        <Tooltip text="Download.">
          <Download data-testid="Download" style={{ cursor: 'pointer' }} onClick={openModal} />
        </Tooltip>
      </DownloadIconWrapper>
      <CSVDownloadModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        onConfirm={handleDownload}
        indexCSVDownload={indexCSVDownload}
        csvDownload={csvDownload}
        error={error}
        setError={setError}
        isLoading={isLoading}
      />
    </>
  );
}

function MainPageContent({
  sortedMenuItems,
  selectedWorkspace,
  isBTGuideError,
  btGuideUrl,
  AllReportsIsLoading,
  allWorkspaces,
}: MainPageContentProps): JSX.Element {
  const { formatMessage } = useIntl();
  const SELECTED_LANGUAGE = formatMessage({ id: 'SELECTED_LANGUAGE' });
  const { triggerPageViewed } = usePageViewed();
  const location = useLocation();
  const { preferredLanguage } = usePreferredLanguageV2();

  const { from } = location.state || { from: null };

  const hasHighlight =
    (selectedWorkspace?.workspace?.highlightSection?.powerBiConfig?.reportId?.length ?? 0) > 0;

  const hasBigNumbersSection =
    hasHighlight && selectedWorkspace?.vendorId && selectedWorkspace?.workspace?.id;

  const { workspaceSelected } = useReports();
  const { workspaceData } = useWorkspaceContext();

  const { data } = useGetDatasets({
    country: workspaceData?.country,
    permissionId: workspaceSelected?.segmentId,
    workspaceId: workspaceSelected.workspaceId,
  });
  const datasetsResponse = data?.datasets || [];

  useEffect(() => {
    if (!AllReportsIsLoading) {
      triggerPageViewed({
        app_display_name: 'Analytics',
        app_id: 'PARTNER-REPORTS',
        is_core_event: false,
        page_label: 'Main Page',
        page_name: 'MAIN_PAGE',
        referrer: from,
        workspace_count: allWorkspaces.length,
        theme_count: sortedMenuItems.length,
        origin_theme_tracking_id: null,
        origin_theme_label: null,
        report_count: sortedMenuItems.length,
        origin_report_label: null,
        origin_report_tracking_id: null,
        has_bt_guide: !isBTGuideError,
        occurence_id: '1.1',
        preferred_language: preferredLanguage ?? 'en-US',
      });
    }
  }, [AllReportsIsLoading]);

  return (
    <>
      <HeaderContainer
        pageTitle={formatMessage({ id: 'MAIN_PAGE.TITLE' })}
        showAnalyticsAdminButton
      />
      <Styled.Container key={selectedWorkspace?.segment?.name}>
        {hasBigNumbersSection && <BigNumbersSection selectedWorkspace={selectedWorkspace} />}
        <Styled.Row hasBTGuide={!isBTGuideError}>
          {selectedWorkspace?.workspace?.id && (
            <ReportsSection
              hasBTGuide={!isBTGuideError}
              workspaceNameSelected={selectedWorkspace.workspace.title[SELECTED_LANGUAGE]}
              segmentIdSelected={selectedWorkspace?.segment?.id}
              allReportsByTheme={sortedMenuItems}
              isLoading={AllReportsIsLoading}
            />
          )}
          {!isBTGuideError && <BTGuideSection btGuideUrl={btGuideUrl.url} />}
        </Styled.Row>
      </Styled.Container>
      <WorkspaceSection
        allWorkspaces={allWorkspaces}
        selectedWorkspace={selectedWorkspace?.workspace?.id || ''}
      />
      {datasetsResponse.length > 0 && (
        <Container>
          <CardDataset border="medium" elevated="small">
            <ContainerTitle>
              <Heading size="H3">
                {formatMessage({ id: 'MAIN_PAGE.DATASET_SECTION.TITLE' })}
              </Heading>

              <Paragraph css={{ opacity: '56%', minHeight: '24px' }}>
                {formatMessage({ id: 'MAIN_PAGE.DATASET_SECTION.SUBTITLE' })}
              </Paragraph>
            </ContainerTitle>

            <DatasetTable datasets={datasetsResponse} />
          </CardDataset>
        </Container>
      )}
    </>
  );
}

export default MainPageContent;
